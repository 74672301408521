import React from 'react'

const mystyle = {
    width: "100%", 
    backgroundColor: "white",
    height: "10vh"
  };

const Ads = () => {

  return (
<div className="ads" style={mystyle}>
    Ads
</div>
  )
}

export default Ads