import React, { useState, useEffect, useRef } from "react";
import "./Chat.scss";
import Message from "./Message/Message";

const Chat = (props) => {
  const divRref = useRef(null);

  const [greeting,setGreeting] = useState(false)
  const [isFullScreen, setFullscreen] = useState(false);

  useEffect(() => {
    divRref.current.scrollIntoView({ behavior: "smooth" });

    // setting the state of welcomeMessage
    if (localStorage.getItem("hasVisited")){
    setGreeting(false)
    } else {
    // creating the "hasVisited" key value pair in localStorage if it doesnt exist
    localStorage.setItem("hasVisited", "true")
    setGreeting(true)
    console.log("welcome",localStorage)
    }
  });

  return (
    <div>
      <div className="welcomeContainer">
       { (greeting === false) ? <p> Welcome back</p> : <p> Welcome! </p>}
      </div>
    <div className="chatBox">
      {props.messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      <div style={{ float:"left", clear: "both" }}
             ref={(el) => { divRref.current = el; }}>
        </div>
    </div>
    </div>
  );
};

export default Chat;
